var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid gap-4",style:(_vm.blockStyles)},[_c('div',{class:{
      'markdown-render space-y-2 px-4 py-3': true,
      'col-start-1 row-start-1 text-left': _vm.side === 'left',
      'col-start-2 row-start-1 text-right': _vm.side === 'right'
    }},[(_vm.content.title)?_c('h2',[_vm._v(" "+_vm._s(_vm.content.title)+" ")]):_vm._e(),(_vm.content.content)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.content.content)}}):_vm._e(),_c('div',{staticClass:"grid auto-cols-fr auto-rows-auto gap-x-4 gap-y-2"},[(_vm.content.contact_name_show)?_c('div',{staticClass:"col-start-1 bg-gray-100 px-2 py-1 h-full"},[_vm._v(" Name ")]):_vm._e(),(_vm.content.contact_email_show)?_c('div',{staticClass:"col-start-1 bg-gray-100 px-2 py-1 h-full"},[_vm._v(" E-mail Address ")]):_vm._e(),(_vm.content.contact_phone_show)?_c('div',{staticClass:"col-start-1 bg-gray-100 px-2 py-1 h-full"},[_vm._v(" Phone ")]):_vm._e(),(_vm.content.contact_message_show)?_c('div',{staticClass:"col-start-2 row-start-1 row-span-3 bg-gray-100 px-2 py-1 h-full"},[_vm._v(" Message ")]):_vm._e()])]),(_vm.backgroundImage)?_c('div',{class:{
      'row-end-3': true,
      'col-start-2 row-start-1': _vm.side === 'left',
      'col-start-1 row-start-1': _vm.side === 'right'
    }},[_c('img',{staticClass:"w-full h-full object-cover",attrs:{"src":_vm.backgroundImage.medium,"alt":_vm.content[("background_image_" + (_vm.item.key) + "_alt")] ? _vm.content[("background_image_" + (_vm.item.key) + "_alt")] : ''}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }