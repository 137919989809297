<template>
  <div class="grid gap-4" :style="blockStyles">
    <div
      :class="{
        'markdown-render space-y-2 px-4 py-3': true,
        'col-start-1 row-start-1 text-left': side === 'left',
        'col-start-2 row-start-1 text-right': side === 'right'
      }"
    >
      <h2 v-if="content.title">
        {{ content.title }}
      </h2>
      <div
        v-if="content.content"
        v-html="content.content"
      />
      <div class="grid auto-cols-fr auto-rows-auto gap-x-4 gap-y-2">
        <div v-if="content.contact_name_show" class="col-start-1 bg-gray-100 px-2 py-1 h-full">
          Name
        </div>
        <div v-if="content.contact_email_show" class="col-start-1 bg-gray-100 px-2 py-1 h-full">
          E-mail Address
        </div>
        <div v-if="content.contact_phone_show" class="col-start-1 bg-gray-100 px-2 py-1 h-full">
          Phone
        </div>
        <div v-if="content.contact_message_show" class="col-start-2 row-start-1 row-span-3 bg-gray-100 px-2 py-1 h-full">
          Message
        </div>
      </div>
    </div>
    <div
      v-if="backgroundImage"
      :class="{
        'row-end-3': true,
        'col-start-2 row-start-1': side === 'left',
        'col-start-1 row-start-1': side === 'right'
      }"
    >
      <img
        :src="backgroundImage.medium"
        :alt="content[`background_image_${item.key}_alt`] ? content[`background_image_${item.key}_alt`] : ''"
        class="w-full h-full object-cover"
      />
    </div>
  </div>
</template>
<script>
export default {
  friendlyName: 'Photo/Content Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    },

    side () {
      return this.content.side ? this.content.side : 'right'
    },

    width () {
      return this.content.width ? this.content.width : '33%'
    },

    backgroundImage () {
      return this.content.images[`background_image_${this.item.key}`] ? this.content.images[`background_image_${this.item.key}`] : null
    },

    blockStyles () {
      return {
        gridTemplateColumns: this.side === 'right' ? `${this.width} auto` : `auto ${this.width}`
      }
    }
  }
}
</script>
